<template>
  <Page :title="$t('contact.title')" :classes="['page--static', 'page--contact']">
    <form class="form" @submit="true">

      <InputText
        name="name"
        :label="$t('contact.fields.name')"
        v-model.trim="name"
        :isRequired="true"
      ></InputText>

      <InputEmail
        name="email"
        :label="$t('contact.fields.email')"
        v-model.trim="email"
        :isRequired="true"
      ></InputEmail>

      <InputTextArea
        name="message"
        :label="$t('contact.fields.message')"
        v-model.trim="message"
        :isRequired="true"
      ></InputTextArea>

      <Button
        :isLoading="isLoading"
        :isDisabled="isInvalid"
        :classes="['btn--accent', 'btn--wide']"
        @onclick="submit()">
          <span v-show="!isLoading">{{$t('contact.button.title')}}</span>
          <span v-show="isLoading">{{$t('contact.button.pending')}}</span>
      </Button>
    </form>
  </Page>
</template>

<script>
import Check from '@/services/check/Check';

export default {
  name: 'ContactPage',
  data() {
    return {
      isLoading: false,
      name: '',
      email: '',
      message: ''
    };
  },
  computed: {
    isInvalid() {
      return !Check.isValidEmail(this.email);
    },
  },
  methods: {
    resetForm() {
      this.name = ''
      this.email = ''
      this.message = ''
    },
    submit() {
      this.isLoading = true;
      if (this.isInvalid) return;

      const data = {
        name: this.name,
        email: this.email,
        message: this.message
      }

      this.$store.dispatch('post', {
        url: 'contact',
        data
      }).then(() => {
        this.$store.dispatch('meeseeks', {
          type: 'success',
          message: this.$t('contact.messages.success'),
        });
        this.resetForm()
      }).catch(() => {
        this.$store.dispatch('meeseeks', {
          type: 'error',
          message: this.$t('contact.messages.error'),
        });
      });
      this.isLoading = false
    }
  }
};
</script>
